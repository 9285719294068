// Core
import { PropsWithChildren } from "react";
import cx from "classnames";

// Components
import { Skeleton } from "antd";

// Utils
import st from "./styles.module.css";

type TitleAlignmentVerticalType = "" | "top" | "middle" | "bottom";
type TitleAlignmentHorizontalType = "left" | "center" | "right";
type TitleSizeType =
  | "12"
  | "14"
  | "16"
  | "18"
  | "20"
  | "24"
  | "22"
  | "30"
  | "32"
  | "36"
  | "38"
  | "44";
type TitleSkeletonWidthType = "10" | "12" | "14" | "16" | "18" | "20" | "40" | "full";
type TitleFontWeightType = "100" | "200" | "400" | "600" | "700" | "800";
type TitleFontType = "system" | "base" | "second";
type TitleTransformType = "" | "capitalize" | "uppercase" | "lowercase";
type TitleColorWhiteType = "white-100";
type TitleColorGreyType =
  | "gray-100"
  | "gray-200"
  | "gray-400"
  | "gray-500"
  | "gray-600"
  | "gray-700"
  | "gray-900"
  | "gray-950"
  | "gray-1000"
  | "gray-6"
  | "dark";
type TitleBlackWhiteType = "black" | "dark";
type TitleColorBlueType = "blue-600" | "blue-700";
type TitleColorOrangeType = "orange-10";
type TitleColorType =
  | TitleColorWhiteType
  | TitleColorGreyType
  | TitleColorBlueType
  | TitleColorOrangeType
  | TitleBlackWhiteType
  | "inherit";

export type TypographyTitleProps = PropsWithChildren<{
  loading?: boolean;
  tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  alignmentVertical?: TitleAlignmentVerticalType;
  alignmentHorizontal?: TitleAlignmentHorizontalType;
  box?: "full";
  size?: TitleSizeType;
  skeletonWidth?: TitleSkeletonWidthType;
  color?: TitleColorType;
  font?: TitleFontType;
  fontWeight?: TitleFontWeightType;
  transform?: TitleTransformType;
  testId?: string;
  className?: string;
  gutter?: "mb-4" | "mb-8" | "mb-12" | "mb-16" | "mb-24" | "mb-32";
}>;

export const Title = (props: TypographyTitleProps) => {
  const {
    tag: Element = "h2",
    alignmentVertical = "",
    alignmentHorizontal = "",
    size = "20",
    skeletonWidth = "full",
    color = "dark",
    font = "",
    fontWeight = "600",
    transform = "",
    className = "",
    box,
    testId,
    children,
    loading,
    gutter,
  } = props;

  const titleSt = cx(className, st.title, {
    [st[`title-size-${size}`]]: Boolean(size),
    [st[`title-font-${font}`]]: Boolean(font),
    [st[`title-font-weight-${fontWeight}`]]: Boolean(fontWeight),
    [st[`title-alignment-vertical-${alignmentVertical}`]]: Boolean(alignmentVertical),
    [st[`title-alignment-horizontal-${alignmentHorizontal}`]]: Boolean(alignmentHorizontal),
    [st[`title-color-${color}`]]: Boolean(color),
    [st[`title-transform-${transform}`]]: Boolean(transform),
    [st["title-box-full"]]: box === "full",
    [st[`title--${gutter}`]]: Boolean(gutter),
  });
  const skeletonStyles = cx(st["title-skeleton"], {
    [st[`title-skeleton-height-${size}`]]: Boolean(size),
    [st[`title-skeleton-width-${skeletonWidth}`]]: Boolean(skeletonWidth),
  });

  if (loading) {
    return <Skeleton.Input active className={skeletonStyles} />;
  }

  return (
    <Element data-testid={testId} className={titleSt}>
      {children}
    </Element>
  );
};
