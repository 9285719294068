export const getFormatIconName = (str: string) =>
  str
    .split(/\.?(?=[A-Z])/)
    .join("-")
    .toLowerCase();

/*
Generage SVG sprite

  https://github.com/svg-sprite/svg-sprite
  https://www.npmjs.com/package/svg-sprite-generator
  // fallback with nextjs
  https://github.com/JetBrains/svg-sprite-loader

 1. Use special custom script in package.json
 "gen:icons": "svg-sprite-generate -d ./components/Icon/assets/icons -o ./components/Icon/assets/sprite.svg"
  Add to sprite file width="100" height="100" to svg tag
  ```
  <svg  width="100" height="100">
  ```

 2. Add config to webpack in next.config.ts

    const SvgSpriteLoaderPlugin = require('svg-sprite-loader/plugin')
    ..
    config.module.rules.push({
      test: /\.svg$/,
      loader: 'svg-sprite-loader',

      options: {
        minifyIds: false,
        esModule: false,
        extract: true,
        symbolId: '[name]',
        spriteFilename: 'static/icon-sprite.svg',
      }
    })
    config.plugins.push(new SvgSpriteLoaderPlugin({
      plainSprite: true,
    }));

   Copy svg-sprite from .next/staic/icon-sprite.svg
  Update icons in "./Icon/assets/sprite.svg".
  Add to sprite file width="100" height="100" to svg tag
  ```
  <svg  width="100" height="100">
  ```
*/
