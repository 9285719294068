"use client";

// Store
import { useStore } from "@/client/hooks/use-store";

export const useModalCtrl = () => {
  const {
    controls: { getOnPageModals, getOnPageModalProps, setOnPageModal, flushModals },
  } = useStore();

  const getModalPropsByName = <T>(name: string): T => {
    return getOnPageModalProps(name) as T;
  };

  return {
    activeModals: getOnPageModals(),
    setModalStateByName: setOnPageModal,
    getModalPropsByName,
    flushModals,
  };
};
