// Components
import { Row as AntRow } from "antd";

// Definitions
import type { RowProps as AntRowProps } from "antd";

// Utils
import { ROW_GUTTER_TYPE } from "./utils";

type RowProps = AntRowProps;

export const Row = (props: RowProps) => {
  return <AntRow {...props} />;
};

Row.gutterType = ROW_GUTTER_TYPE;
