// Components
import { Col as AntCol } from "antd";

// Definitions
import type { ColProps as AntColProps } from "antd";

type ColProps = AntColProps;

export const Col = (props: ColProps) => {
  return <AntCol {...props} />;
};
