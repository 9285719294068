// Definitions
import { Gutter } from "antd/es/grid/row";

export const ROW_GUTTER_TYPE: {
  xsm: {
    horizontal: [Gutter, Gutter];
  };
  sm: {
    vertical: [Gutter, Gutter];
  };
  md: {
    horizontal: [Gutter, Gutter];
    all: [Gutter, Gutter];
  };
} = {
  xsm: {
    horizontal: [4, 0],
  },
  sm: {
    vertical: [0, 8],
  },
  md: {
    horizontal: [16, 0],
    all: [16, 16],
  },
};
