import { ValueOf } from "@/common/types/basic";

export const MODALS_BOOK = {
  callback: "callback",
  callbackLimit: "callbackLimit",
  callbackPersonalLimit: "callbackPersonalLimit",
  passwordCreate: "passwordCreate",
  signupSuccess: "signupSuccess",
  signOutConfirm: "signOutConfirm",
  massSearchInstructions: "massSearchInstructions",
  forgotPassword: "forgotPassword",
  vatServiceUnavailable: "vatServiceUnavailable",
  vatInvalid: "vatInvalid",
  searchBar: "searchBar",
} as const;

export type ModalNamesType = ValueOf<typeof MODALS_BOOK>;

export const modalDefaultWidth = 438;
